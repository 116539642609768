<template>
  <label v-bind="$attrs" :class="cn(labelVariants({ variant: props.variant, class: props.class }))">
    <slot />
  </label>
</template>

<script lang="ts">
export const labelVariants = cva(
  "block select-none font-semibold group-focus-within:text-primary transition-colors duration-150 ease-in-out group-disabled:cursor-not-allowed group-disabled:opacity-70",
  {
    variants: {
      variant: {
        default: "text-muted-foreground",
        primary: "text-primary",
        error: "!text-destructive",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)
</script>

<script setup lang="ts">
import { type VariantProps, cva } from "class-variance-authority"
import { type ClassValue } from "clsx"

const props = defineProps<{
  class?: ClassValue
  variant?: VariantProps<typeof labelVariants>["variant"]
}>()
</script>
